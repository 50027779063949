// Use dynamic import here to allow webpack to interface with module federation code
// window.remote1Url = 'http://localhost:3001';
// window.libsUrl = 'http://localhost:3002';

import("./bootstrap");

// if (module.hot) {
//   module.hot.accept("/", () => {
//     // 对更新过的 library 模块做些事情...
//     // console.log('xxxx')
//   });
// }
